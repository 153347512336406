import { computed, useContext } from '@nuxtjs/composition-api'
import { useSsrState } from '@ha/components'

const honeyPot = useSsrState('honeyPot', () => '')

export default () => {
  const { $config } = useContext()

  const canHoneyPotBlock = computed(() => $config.ACTIVATION_HONEYPOT)

  const setHoneyPotValue = (value: string) => {
    honeyPot.value = value
  }

  return {
    honeyPot,
    canHoneyPotBlock,
    setHoneyPotValue
  }
}
