import { computed, useStore } from '@nuxtjs/composition-api'
import { readonly, watch } from 'vue'
import { useSsrState } from '@ha/components'
import useStoreData from '@/composables/useStoreData'
import { Store } from 'vuex/types/index'
import { FormsStoreRootState } from '~/types/common.interface'
import { AxiosError } from 'axios'
import { NuxtError } from '@nuxt/types'

const refreshCartTimer = useSsrState('refreshCartTimer', () => null)
const canRefresh = useSsrState('canRefresh', () => false)
const nbCycleWithoutAction = useSsrState('nbCycleWithoutAction', () => 0)
const isCartExpired = useSsrState('isCartExpired', () => false)

export default () => {
  const { cart, storeRouteParams } = useStoreData()
  const store: Store<FormsStoreRootState> = useStore()
  const cartRealLifeSpanMilliseconds = computed(
    () => store.state.carts.currentCartExpirationTime - Date.now()
  )

  const thirdOfCartLifeSpanInMilliseconds = computed(() => {
    return cart.value.id 
      ? cartRealLifeSpanMilliseconds.value / 3
      : 0
  })
  const refreshCartSession = async () => {
    return store.dispatch('carts/refreshCartSession', {
      storeRouteParams: storeRouteParams.value,
      cartId: cart.value.id
    })
  }
  const recordAction = () => {
    if (!refreshCartTimer.value) {
      return
    }

    canRefresh.value = true
  }

  const setIsCartExpired = (isExpired = false) => {
    isCartExpired.value = isExpired
  }

  const launchRefreshCartTimer = () => {
    nbCycleWithoutAction.value += 1
    const timeout =
      // removes 500 ms to prevent last call to be fired right at expiration time
      nbCycleWithoutAction.value <= 2
        ? thirdOfCartLifeSpanInMilliseconds.value
        : thirdOfCartLifeSpanInMilliseconds.value - 500

    refreshCartTimer.value = setTimeout(onRefreshCycleEnds, timeout)
  }

  const initRefreshCartCycles = () => {
    setIsCartExpired(false)
    nbCycleWithoutAction.value = 0
    canRefresh.value = false

    if (cartRealLifeSpanMilliseconds.value > 0) launchRefreshCartTimer()
    else setIsCartExpired(true) // Prevent to run 3 <=0 timeouts
  }

  const hasAlreadyExpired = (error: AxiosError & NuxtError) => {
    return error.response?.status === 404 || error.statusCode === 404
  }

  const onRefresh = async () => {
    try {
      await refreshCartSession()
      initRefreshCartCycles()
    } catch (error: any) {
      if (hasAlreadyExpired(error) || nbCycleWithoutAction.value >= 3) setIsCartExpired(true)
      else launchRefreshCartTimer()
    }
  }

  const clearSessionTimeout = () => {
    clearTimeout(refreshCartTimer.value)
  }

  const onRefreshCycleEnds = () => {
    clearSessionTimeout()
    refreshCartTimer.value = null

    if (!canRefresh.value && nbCycleWithoutAction.value >= 3) {
      setIsCartExpired(true)
    } else if (canRefresh.value) {
      onRefresh()
    } else {
      launchRefreshCartTimer()
    }
  }

  // Used to make sure that user has 15min to finalize payment
  const forceRefreshAndClearSessionTimeout = async () => {
    try {
      clearSessionTimeout()
      await refreshCartSession()
    } catch (error: any) {
      setIsCartExpired(hasAlreadyExpired(error))
      throw error
    }
  }

  /**
   * Three use cases:
   * 1 - User get on the form for 1st time
   *      - A cart will be created after click on next step
   * 2 - User reload the page and had already a cart
   *      - The watcher will be fired when vuex-persistedstate hydration is finished
   * 3- User stayed idle long enough for the cart to expired
   *    - The expiration process inits
   *      - Cart is removed from store
   *      - User is redirected in step 1
   *    - Same flow as use case 1
   */
  watch(cart, (newCart, oldCart) => {
    if (!oldCart && newCart) initRefreshCartCycles()
  })

  return {
    isCartExpired: readonly(isCartExpired),
    recordAction,
    forceRefreshAndClearSessionTimeout
  }
}
