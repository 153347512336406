import { onMounted, onBeforeUnmount, useContext, computed } from '@nuxtjs/composition-api'
import { controlRecaptcha } from '../services/honeyPotService'
import useHoneyPot from '../composables/useHoneyPot'

export default () => {
  const { 
    $config, 
    // @ts-ignore
    $recaptcha 
  } = useContext()
  const { honeyPot, canHoneyPotBlock } = useHoneyPot()

  const requiresRecaptcha = computed(() => $config.NUXT_ENV_ONE_PAGE_RECAPTCHA_ENABLED)

  const getRecaptchaControl = async () => {
    const token = await $recaptcha.execute('submitSummary')
    // this is a second verification server side. Do not trigger if token not already good
    return controlRecaptcha(token)
  }

  const doesRecaptchaBlock = async () => {
    if (canHoneyPotBlock.value) return !!honeyPot.value

    if (requiresRecaptcha.value) {
      const responseRecaptcha = await getRecaptchaControl()
      return !responseRecaptcha?.success
    }

    return false
  }

  onMounted(async () => {
    if (requiresRecaptcha.value) await $recaptcha.init()
  })
  onBeforeUnmount(async () => {
    if (requiresRecaptcha.value) await $recaptcha.destroy()
  })

  return {
    requiresRecaptcha,
    doesRecaptchaBlock
  }
}
