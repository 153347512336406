<template>
  <div class="Card">
    <h4 v-if="hasTitleSlot" class="Card--Title">
      <slot name="title" />
    </h4>
    <div v-if="hasContentSlot" class="Card--Content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    color: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    hasTitleSlot() {
      return !!this.$slots.title
    },
    hasContentSlot() {
      return !!this.$slots.content
    }
  }
}
</script>

<style lang="scss">
.Card {
  margin-top: $ha-spacing-large;
  padding: $ha-spacing-large;
  background-color: var(--ha-color-white);
  background-clip: padding-box;
  border-radius: $ha-radius-large;
  box-shadow: $ha-box-shadow-close;

  &.Card-Split {
    padding: 0;

    section:not(.Is-Paddingless) {
      padding: $ha-spacing-large;

      + section:not(.Is-Borderless) {
        border-top: $ha-border-width-regular solid set-alpha(border, 0.5);
      }
    }
  }

  &--Title {
    color: var(--ha-color-primary);
  }
}
</style>
