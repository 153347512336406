export const handledHTTPErrors = [
  '400',
  '401',
  '403',
  '404',
  '408',
  '409',
  '413',
  '415',
  '421',
  '500'
]

export const routeCodes = {
  'POST /carts': '#0000001',
  'PUT /carts': '#0000002',
  'PUT /payer': '#0000003',
  'PUT /carts/cart-one-page': '#0000005',
  'POST /carts/init-payment': '#0000006',
  'GET /forms/agg/form': '#0000007'
}
