import { type Ref, computed, ref } from 'vue'
import useStoreData from '@/composables/useStoreData'
import { useContext } from '@nuxtjs/composition-api'
import { functions, enum as enums } from '@ha/helpers'
import { useSsrState } from '@ha/components'
import { useStore } from '@nuxtjs/composition-api'
import useCart from '@/composables/useCart'
import useNotifications from '@/composables/useNotifications'

const modalitiesStatus: Ref<number> = useSsrState('modalitiesStatus', () => 1)

export default () => {
  const { form } = useStoreData()
  const { $config, i18n } = useContext()
  const store = useStore()
  const { cartStore } = useCart()
  const storeData = useStoreData()
  const { onError } = useNotifications()

  const totalAmount = computed(() => {
    return `${functions.convertToEuros(cartStore.value?.totalAmount || 0)} €`
  })

  const availableTiersFrequencies = computed(() => {
    const excludedFormsItems = form.value.tiers
      .filter(tier => tier.isExcludedFromFormPaymentTerms)
      .map(tier => tier.id)

    const eligibleCartItems = cartStore.value?.itemList.filter(tier => !excludedFormsItems.includes(tier.tierId))

    return eligibleCartItems || []
  })

  const isPaymentTermsEnabled = computed(() => {
    return Boolean(
      form.value.formType === enums.FormType.SHOP &&
      $config.PAYMENT_TERMS_ENABLED
    )
  })

  const isFrequenciesAvailable = computed(() => {
    return Boolean(availableTiersFrequencies.value.length)
  })

  const availableFrequencies = computed(() => {
    return cartStore?.value?.availableFrequencies
  })

  const isInstallmentLoading = ref(true)

  interface Error {
    message: string,
    statusCode: number
  }
  const changeModality = async (installments: number) => {
    modalitiesStatus.value = installments
    isInstallmentLoading.value = true
    try {
      await store.dispatch('carts/putInstallments', {
        id: cartStore.value.id,
        installments,
        storeRouteParams: storeData.storeRouteParams.value
      })
    } catch (error: unknown) {
      onError(error, {
        type: 'danger',
        title: i18n.t(`error.code.${(error as Error).statusCode}.title`),
        body: `${(error as Error).message}`,
        timeout: 5000
      })
      // set to one to avoid wrong display with errors on installments
      modalitiesStatus.value = 1
    }
    isInstallmentLoading.value = false
  }

  const currentPaymentTerms = computed(() => {
    modalitiesStatus.value = cartStore?.value?.paymentTerms.length || 1

    return (
      cartStore?.value?.paymentTerms || []
    )
  })

  return {
    isPaymentTermsEnabled,
    isFrequenciesAvailable,
    changeModality,
    availableFrequencies,
    modalitiesStatus,
    totalAmount,
    isInstallmentLoading,
    currentPaymentTerms
  }
}
