import axios from 'axios'
import { getHttpsAgent, getProxyBaseURL } from '../helpers/httpUtils'

/**
 * Create axios instance for google recaptcha verification (server side)
 * See https://developers.google.com/recaptcha
 * @returns {AxiosInstance}
 */
const createHttpGoogleRecaptcha = () => {
  const httpGoogleRecaptcha = axios.create({
    withCredentials: false,
    httpsAgent: getHttpsAgent(),
    baseURL: 'https://www.google.com/recaptcha/api/siteverify'
  })
  return httpGoogleRecaptcha
}

/**
 * Create axios instance for auth proxy
 * We use it to make oauth2 calls in nuxt's server and obfuscate api and client's id
 * @returns {AxiosInstance}
 */
const createHttpProxyRecaptcha = () => {
  const httpProxyRecaptcha = axios.create({
    withCredentials: true,
    httpsAgent: getHttpsAgent(),
    baseURL: `${getProxyBaseURL()}/recaptcha`
  })

  return httpProxyRecaptcha
}

/**
 * Makes the request to get recaptcha token
 * @param {string} token - leave empty to request a brand new token
 * @returns {Object} response object
 */

const controlRecaptcha = async (token: string) => {
  const httpProxyRecaptcha = createHttpProxyRecaptcha()
  const response = await httpProxyRecaptcha.get(`/${token}`)
  return response.data
}

export { createHttpGoogleRecaptcha, createHttpProxyRecaptcha, controlRecaptcha }
