import https from 'https'

/**
 * Create https agent
 * @returns {Agent}
 */
const getHttpsAgent = () => {
  return new https.Agent({
    rejectUnauthorized: process.env.NODE_ENV !== 'development'
  })
}

/**
 * Get url of nuxt's serveur
 * @returns {String}
 */
const getProxyBaseURL = () => {
  const isDev = process.env.NODE_ENV === 'development'
  const baseUrlServer = isDev ? 'https://127.0.0.1:443' : 'http://localhost:3000'
  /* We have to prefix all server middleware url by '/forms/' otherwise ha-proxy make bad redirection */
  const baseURL = process.server ? `${baseUrlServer}/forms` : `${window.location.origin}/forms`
  return baseURL
}

export { getHttpsAgent, getProxyBaseURL }
