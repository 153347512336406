import { Ref, computed, ref } from 'vue'
import { functions, haMoment } from '@ha/helpers'
import { useSsrState } from '@ha/components'
import { useStore, useRouter, useContext } from '@nuxtjs/composition-api'
import useRouteParams from '@/composables/useRouteParams'
import useStoreData from '@/composables/useStoreData'
import usePayerPersonalInformations from '@/components/payers/usePayerPersonalInformations'
import useVoluntaryContribution from '@/components/voluntary-contributions/useVoluntaryContribution'
import useFormType from '@/composables/useFormType'
import useCartRefresh from '@/composables/useCartRefresh'
import useNotifications from '@/composables/useNotifications'
import useTracking from '@/composables/useTracking'
import useRecaptcha from '@/composables/useRecaptcha'
import useHoneyPot from '@/composables/useHoneyPot'
import useLegalCheckbox from '@/components/carts/useLegalCheckbox'
import { routeCodes, handledHTTPErrors } from '@/helpers/errorLogs'
import { datadogLogs } from '@datadog/browser-logs'
import { Tier, TierMistack } from '~/components/tiers/tiers.interface'
import { Cart, CartUpsertPayload } from '~/components/carts/carts.interface'
import { CollectionOf } from '~/types/common.interface'
import { NuxtError } from '@nuxt/types'
import { CustomFieldAltered } from '~/components/custom-fields/customField.interface'

const cartTiers: Ref<Tier[]> = useSsrState('cartTiers', () => [])

export default () => {
  const STEP_TIERS_CHOICE = 1
  const STEP_PARTICIPANTS = 2
  const STEP_PAYER = 3
  const STEP_SUMMARY = 4

  const store = useStore()
  const router = useRouter()
  const { organizationSlug, formSlug, formTypeUrl } = useRouteParams()
  const { form, organization, storeRouteParams } = useStoreData()
  const { personalInformations, customFields } = usePayerPersonalInformations()
  const { tipSelected } = useVoluntaryContribution()
  const { isSale } = useFormType()
  const { forceRefreshAndClearSessionTimeout } = useCartRefresh()
  const { i18n, $config } = useContext()
  const { onError } = useNotifications()
  const { trackingMethods, trackingIdentify } = useTracking()
  const { doesRecaptchaBlock, requiresRecaptcha } = useRecaptcha()
  const { honeyPot, canHoneyPotBlock } = useHoneyPot()
  const { isAllLegalCheckboxesChecked } = useLegalCheckbox()

  const cartId = ref(null)
  const isSubmittingCart = ref(false)

  const getCartTier = (tier: TierMistack): TierMistack => {
    return {
      tierId: tier.id,
      tierType: tier.tierType,
      customAmount: tier.customAmount,
      dayOfLevy: tier.dayOfLevy,
      price: tier.price,
      vatRate: tier.vatRate,
      paymentFrequency: tier.paymentFrequency,
      extraOptions: [],
      isEligibleTaxReceipt: tier.isEligibleTaxReceipt
    }
  }
  const getCartPayer = () => ({
    firstName: personalInformations.value.firstName,
    lastName: personalInformations.value.lastName,
    email: personalInformations.value.email,
    dateOfBirth: haMoment(personalInformations.value?.birthDate, 'DD/MM/YYYY').format(),
    consent: true,
    place: {
      address: personalInformations.value.address,
      city: personalInformations.value.city,
      zipCode: personalInformations.value.zipCode,
      country: personalInformations.value.country
    },
    companyName: personalInformations.value.isCompany
      ? personalInformations.value.companyName
      : null,
    companyLegalStatus:
      personalInformations.value.isCompany && !isSale
        ? personalInformations.value.companyLegalStatus
        : null,
    companySiren:
      personalInformations.value.isCompany && !isSale
        ? personalInformations.value.companySiren
        : null
  })

  const cartTiersFormatted = computed(() => cartTiers.value.map(getCartTier))
  const cart = computed(
    () =>
      ({
        formSlug: form.value?.formSlug,
        organizationSlug: organization.value?.organizationSlug,
        formType: form.value?.formType,
        items: cartTiersFormatted.value
      } as CartUpsertPayload)
  )
  const cartStore = computed<Cart>(() => store.getters['carts/getCart'](storeRouteParams.value))
  const cartItems = computed(() => cartStore.value?.itemList || [])
  const cartOptions = computed(() =>
    cartItems.value.flatMap(item => item.extraOptions?.filter(option => option.price))
  )
  const cartTickets = computed(() => cartItems.value.filter(item => item.tierType !== 'Donation'))
  const cartDonations = computed(() => cartItems.value.filter(item => item.tierType === 'Donation'))
  const cartHasScheduledPayments = computed(() => {
    for (const item of cartItems.value) {
      const tier = cartStore.value?.tierList?.find(tier => tier.id === item.tierId)
      if (['Monthly', 'Installment'].includes(tier?.paymentFrequency as string)) return true
    }
    return false
  })

  const totalCartDonationPrice = computed(() =>
    cartDonations.value.reduce((acc, value) => {
      return acc + (value.customAmount ? value.customAmount : 0)
    }, 0)
  )

  const totalCartOptionsPrice = computed(() =>
    cartOptions.value.reduce((acc, value) => {
      return acc + (value?.paymentTerms ? value.paymentTerms[0].realAmount : 0)
    }, 0)
  )

  const totalCartTicketsPrice = computed(() =>
    cartTickets.value.reduce((acc, value) => {
      const frequency = value.paymentTerms?.length || 1
      return acc + (value.customAmount ? value.customAmount : 0) * frequency
    }, 0)
  )

  const discountApplied = computed(
    () => store.getters['carts/getDiscount'](storeRouteParams.value) || 0
  )

  const totalCartPrice = computed(() => {
    const paymentsNumber =
      cartStore.value?.paymentTerms?.filter(item => item.realAmount).length ?? 0
    const fullTip = tipSelected.value * paymentsNumber
    const fullSum =
      totalCartTicketsPrice.value +
      totalCartOptionsPrice.value +
      totalCartDonationPrice.value +
      fullTip
    const paymentTotal = fullSum - discountApplied.value

    return functions.formatThousands(functions.convertToEuros(paymentTotal))
  })

  const cartTipData = computed(() => {
    return {
      min: cartStore.value?.tipData?.tipSuggestionMin || 0,
      max: cartStore.value?.tipData?.tipSuggestionMax || 0,
      default: cartStore.value?.tipData?.tipSuggestion || 0,
      selected: cartStore.value?.tipData?.tipChosen ?? null
    }
  })

  const updateCartTiers = (newCartItems: Tier[]) => {
    cartTiers.value = newCartItems
  }

  const getOrderedCustomFields = () => {
    return cartTiers.value.reduce<{
      currentCustomFields: CollectionOf<any>
      filesToUpload: CustomFieldAltered[]
    }>(
      (acc, tier) => {
        const { customFields: tierCustomFields = [] } = tier

        const fields = tierCustomFields.filter(field => field.type !== 'File')
        const files = tierCustomFields.filter(field => field.type === 'File')

        if (tier.id) {
          acc.currentCustomFields[tier.id] = fields.map(field => {
            return {
              id: field.id,
              type: field.type,
              value: customFields.value[field.id]
            }
          })

          acc.filesToUpload.push(
            // @ts-ignore
            ...files
              .filter(file => !!customFields.value[file.id])
              .map(file => ({
                id: file.id,
                type: file.type,
                fileData: customFields.value[file.id],
                tierId: tier.id
              }))
          )
        }

        return acc
      },
      { currentCustomFields: {}, filesToUpload: [] }
    )
  }

  const uploadFiles = (customFieldFiles: CustomFieldAltered[]) => {
    const uploadStack = customFieldFiles.map(({ fileData, ...customFieldFile }) => {
      return store.dispatch('carts/uploadFile', [cartId.value, fileData]).then(fileName => ({
        ...customFieldFile,
        value: fileName
      }))
    })
    return Promise.all(uploadStack)
  }

  const createCart = async () => {
    const { id } = await store.dispatch('carts/postCart', [
      cartTiersFormatted.value,
      storeRouteParams.value
    ])
    cartId.value = id

    const { currentCustomFields: regularCustomFields, filesToUpload } = getOrderedCustomFields()

    if (filesToUpload.length) {
      const uploadedFiles = await uploadFiles(filesToUpload)
      uploadedFiles.forEach(field => {
        if (field.tierId) {
          regularCustomFields[field.tierId] = [...regularCustomFields[field.tierId], field]
        }
      })
    }

    const fullTierItems = cartTiersFormatted.value.map(tier => {
      if (tier?.tierId && regularCustomFields[tier?.tierId]) {
        return { ...tier, customFields: regularCustomFields[tier?.tierId] }
      }
      return tier
    })

    await store.dispatch('carts/putCart', [fullTierItems, storeRouteParams.value, cartId.value])

    await store.dispatch('carts/putPayer', {
      payer: { payer: getCartPayer() },
      metaData: storeRouteParams.value,
      id
    })
    /* Need to call putTip api after putPayer otherwise there is a server error */
    await store.dispatch('carts/putTip', [tipSelected.value, storeRouteParams.value, cartId.value])
  }
  const redirectToPayment = async () => {
    const { redirectUrl, userId } = await store.dispatch('carts/initPayment', cartId.value)

    if (userId) {
      trackingIdentify(userId, {
        userId: userId
      })
    }
    router.push({
      name: 'PaymentRedirectForm',
      params: {
        url: redirectUrl,
        organizationSlug: organizationSlug.value,
        campaignSlug: formSlug.value,
        formType: formTypeUrl.value
      }
    })
  }

  const submitCart = async () => {
    try {
      isSubmittingCart.value = true

      const isRecaptchaBlocked = await doesRecaptchaBlock()

      if ($config.DD_RUM_SESSION_LOGS_ENABLE) {
        const { logger } = datadogLogs
        logger.info('Reglementaire - recaptcha', {
          messageContext: 'goToNextStep',
          json: {
            project: 'forms',
            step: 'summary',
            canHoneyPotBlock: canHoneyPotBlock.value,
            honeypotValue: honeyPot.value,
            isHoneypotValid: !honeyPot.value,
            isRecaptchaActive: requiresRecaptcha.value,
            isRecaptchaBlocked
          }
        })
      }

      // protect against forge (if user go in dev mode and delete the disabled on the next button)
      if (isRecaptchaBlocked || !isAllLegalCheckboxesChecked.value) return

      await store.dispatch('carts/setPaiementStatus', [])

      const cart = store.getters['carts/getCart'](storeRouteParams.value)
      cartId.value = cart.id

      // Stockage du tip dans le store
      await store.dispatch('carts/putTip', [
        tipSelected.value,
        storeRouteParams.value,
        cartId.value
      ])

      await forceRefreshAndClearSessionTimeout()
      trackingMethods?.onFormSubmission()
      await redirectToPayment()
    } catch (error: any | NuxtError) {
      const status =
        error?.statusCode?.toString() || error?.response?.status.toString() || 'uncaught'
      const translationKey = handledHTTPErrors.includes(status) ? status : 'uncaught'

      const toast = {
        type: 'danger',
        title: i18n.t(`error.code.${translationKey}.title`),
        body:
          error.response?.data.errors?.[0]?.message ||
          `${i18n.t(`error.code.${translationKey}.message`)} - ${status} ${
            (routeCodes as any)[error.routeCode]
          }`,
        timeout: 5000
      }
      onError(error, toast)
    }
  }

  return {
    cartId,
    cart,
    cartTiers,
    cartStore,
    cartItems,
    cartOptions,
    cartTickets,
    cartDonations,
    cartHasScheduledPayments,
    totalCartPrice,
    cartTipData,
    isSubmittingCart,
    createCart,
    updateCartTiers,
    redirectToPayment,
    submitCart,
    STEP_TIERS_CHOICE,
    STEP_PARTICIPANTS,
    STEP_PAYER,
    STEP_SUMMARY
  }
}
